@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .header-transition {
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  user-select: none;
}

ol {
  list-style: none outside none;
}

ul {
  list-style: none outside none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}
